<template>
  <div class="notFound"><img src="~@/assets/img/404.jpg" /></div>
</template>
<script>
export default {
  name: "NotFound",
  data() {
    return {};
  },
  mounted: function () {},
};
</script>

<style lang="less">
.notFound {
  text-align: center;
  background-color: #fff;
  img {
    width: 40%;
  }
}
</style>

